import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {closeError} from "../actions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

export default function ErrorDialog(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const error = useSelector(state => {
        return state.error;
    });

    function handleClose() {
        if(error.returnUrl){
            window.location.href = error.returnUrl;
        } else {
            dispatch(closeError());
        }
    }

    return (
        <Dialog onClose={handleClose} open={error.message !== ""} aria-labelledby="confirm-got-error" classes={{
            paper: classes.paper,
        }}>
            <DialogTitle id="confirm-got-error">Ошибка</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <strong>{error.message}</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import React from 'react';
import Connection from "./components/Connection";
import Editor from "./components/Editor";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AssignmentView from "./components/AssignmentView";
import StatusBar from "./components/StatusBar";
import ErrorDialog from "./components/ErrorDialog";
import OkDialog from "./components/OkDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));


function App(props) {
    const classes = useStyles();
    return (
        <Container>
            <div className={classes.root}>
                <Connection assignmentGUID={props.match.params.assignmentGUID} authToken={props.match.params.authToken}/>
                <header className="App-header">
                    <AssignmentView/>
                    <Editor/>
                    <StatusBar/>
                    <ErrorDialog/>
                    <OkDialog/>
                </header>
            </div>
        </Container>
    );
}

export default App;

export const USER_SESSION = "USER_SESSION";
export const UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT";
export const ERROR = "ERROR";
export const OK = "OK";

export function updateUserSession(userSession) {
    return {
        type: USER_SESSION,
        payload: userSession
    };
}

export function updateAssignment(response) {
    return {
        type: UPDATE_ASSIGNMENT,
        payload: response
    };
}

export function haveGotError(error) {
    return {
        type: ERROR,
        payload: error
    };
}

export function closeError() {
    return {
        type: ERROR,
        payload: {message:""}
    };
}

export function haveGotOk(message) {
    return {
        type: OK,
        payload: message
    };
}

export function closeOk() {
    return {
        type: OK,
        payload: {message:""}
    };
}
import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import socketIOClient from "socket.io-client";
import KeyEvent from "../model/KeyEvent";
import {haveGotError, haveGotOk, updateAssignment, updateUserSession} from "../actions";

const KEY_EVENT = "key:event";
const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET;

let socket;

/**
 * @return {null}
 */
export default function Connection(props) {
    console.log("SOCKET_ENDPOINT", SOCKET_ENDPOINT);
    socket = socketIOClient(SOCKET_ENDPOINT + `?assignmentGUID=${props.assignmentGUID}&auth=${props.authToken}`);

    document.onkeydown = (e) => {
        if (socket.connected) {
            let keyEvent = KeyEvent.fromEvent(e);
            socket.emit(KEY_EVENT, keyEvent)
        }
        e.preventDefault();
    };

    const dispatch = useDispatch();
    socket.on("user:session", (userSession) => {
        console.log("user:session", userSession);
        dispatch(updateUserSession(userSession));
    });
    socket.on("assignment", (assignment) => {
        console.log("assignment", assignment);
        dispatch(updateAssignment(assignment));
    });
    socket.on("error", (message) => {
        console.log("error", message);
        dispatch(haveGotError(message));
    });
    socket.on("ok", (message) => {
        console.log("ok", message);
        dispatch(haveGotOk(message));
    });
    return null;
}

/**
 * Hook factory, which creates a `useSocketEmit` hook
 * @returns {Function} A `useSocketEmit` hook
 */
export function createSocketEmitHook() {
    return function useSocketEmit(type, message) {
        socket.emit(type, message)
    };
}

export function useSocketState() {
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        socket.on('connect', () => setIsConnected(true));
        socket.on('disconnect', () => setIsConnected(false))
    });

    return isConnected;
}
/**
 * A hook to access the socket emit method.
 *
 * @returns {function} redux store's `dispatch` function
 *
 */
export var useSocketEmit =
    createSocketEmitHook;